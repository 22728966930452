
  import { Component, Vue, Watch } from 'vue-property-decorator';
  import { Action, Getter, State as StateClass } from 'vuex-class';
  // @ts-ignore
  import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
  import { State } from '@/models/State';
  import { ManagerRole } from '@/models/manager/Manager';
  import { CurrentManager } from '@/models/manager/CurrentManager';
  import ManagersList from '@/components/managers/ManagersList.vue';
  import Modal from '@/components/common/Modal.vue';
  import { DataContainerStatus } from '@/models/Common';
  import { collections } from '@/boot/vue';
  import FormInput from '@/components/common/form-elements/FormInput.vue';

  @Component({
    components: {
      ManagersList,
      FormInput,
      Modal,
    },
  })

  export default class Managers extends Vue {
    // @ts-ignore
    roles = Object.values(ManagerRole);
    selectedRole: ManagerRole = ManagerRole.Editor;
    email = '';
    password = '';
    showModal = false;
    collections = collections

    @Action bindFirestoreReference!: Function;
    @Action(addToastMessage) addToastMessage!: Function;
    @Action setManagerRole!: Function;

    @StateClass specialUsers!: State['specialUsers'];
    @StateClass managerStatus!: State['managerStatus'];
    @StateClass managerRole!: State['managerRole'];
    @StateClass managerRemoval!: State['managerRemoval'];

    @Getter getCurrentManager!: CurrentManager;
    @Getter getCurrentManagerRole!: ManagerRole;

    @Watch('managerStatus', { deep: true })
    onStatusChanged(newState: State['managerStatus'], oldState: State['managerStatus']): void {
      if (newState.status === DataContainerStatus.Success || newState.status === DataContainerStatus.Error) {
        this.handleManagersListChange(newState.status === DataContainerStatus.Success, 'Changed manager status.', newState.error as string);
      }
    }

    @Watch('managerRole', { deep: true })
    onRoleChanged(newState: State['managerRole'], oldState: State['managerRole']): void {
      if (newState.status === DataContainerStatus.Success) {
        // Reset fields and load managers
        this.email = '';
        this.password = '';
        this.selectedRole = ManagerRole.Editor;
        this.showModal = false;

        if (newState.payload && !newState.payload.newUser && newState.payload.role) {
          this.addToastMessage({
            text: `Manager updated for the role ${newState.payload.role}`,
            type: 'success',
          });
        } else if (newState.payload && newState.payload.newUser && newState.payload.role) {
          this.addToastMessage({
            text: `Manager created with the role ${newState.payload.role}`,
            type: 'success',
          });
        } else {
          this.addToastMessage({
            text: 'Manager created',
            type: 'success',
          });
        }
      } else if (newState.status === DataContainerStatus.Error) {
        this.addToastMessage({
          text: newState.error || 'Something went wrong.',
          type: 'danger',
        });
      }
    }

    @Watch('managerRemoval.status')
    onNewManagerRemovalStatus(status: DataContainerStatus): void {
      if (status === DataContainerStatus.Success) {
        this.addToastMessage({
          text: 'Manager removed',
          type: 'success',
        });
      } else if (status === DataContainerStatus.Error) {
        this.addToastMessage({
          text: this.managerRemoval.error || 'Something went wrong.',
          type: 'danger',
        });
      }
    }

    /**
     * Returns whether we're loading data.
     */
    get loading(): boolean {
      return this.managerRemoval.status === DataContainerStatus.Processing;
    }

    /**
     * Current Firebase project ID.
     */
    get firebaseProjectId(): string {
      return process.env.VUE_APP_BLOQADMIN_FIREBASE_PROJECT_ID || 'unknown';
    }

    get isAllowedToSetClaims(): boolean {
      return this.getCurrentManagerRole === ManagerRole.Superadmin || this.getCurrentManagerRole === ManagerRole.Admin;
    }

    get isProcessing(): boolean {
      return this.managerRole.status === DataContainerStatus.Processing;
    }

    addRole(): void {
      this.setManagerRole({
        email: this.email,
        role: this.selectedRole,
        password: this.password,
        managerUid: this.getCurrentManager.uid,
      });
    }

    /**
     * Handle any change for watchers.
     */
    handleManagersListChange(success: boolean, successMessage: string, errorMessage?: string): void {
      if (success) {
        this.addToastMessage({
          text: successMessage,
          type: 'success',
        });
      } else {
        this.addToastMessage({
          text: errorMessage || 'Something went wrong.',
          type: 'danger',
        });
      }
    }
  }
